import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { changeEncoding } from "../../latin-kiril-letters";

import "./EditUserImage.css";

function EditUserImage() {
  const url = localStorage.getItem("url");
  const [userID, setUserID] = useState();
  const [data, setData] = useState();
  const [filtered, setFiltered] = useState();
  const [serviceStatus, setServiceStatus] = useState();
  const [image, setImage] = useState();
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (serviceStatus) {
      (async () => {
        const res = await axios.get(url + "/service-owners", {
          params: { serviceStatus },
        });
        setData(res?.data?.data);
      })();
      setServiceStatus();
    }
  }, [serviceStatus, url]);

  function changeStatus(e) {
    setServiceStatus(e.target.value);
  }

  function fileteredData(e) {
    if (!data) return;
    const filter = data.filter((item) => {
      const value = e.target.value.toLowerCase();
      const kirilOrLatin = changeEncoding(value);
      return (
        item.fullname.toLowerCase().includes(value) ||
        item.fullname.toLowerCase().includes(kirilOrLatin)
      );
    });
    filter.length && setFiltered(filter);
  }

  async function sendImage() {
    if (!image || !userID) return;
    let formData = new FormData();
    formData.append("file", image);
    formData.append("userID", userID);
    const res = await axios.post(url + "/user-image", formData);
    setUserID();
    setImage();
    if (res?.data?.message === "file created") showSuccesMessage();
  }

  function showSuccesMessage() {
    setSuccessMessage(true);
    setTimeout(() => setSuccessMessage(false), 2000);
  }

  return (
    <>
      <div>
        <div className="edit-header">
          <div>
            <button className="top-btn edit-go-back">
              <Link className="service-edit-btn" to={"/"}>
                back home
              </Link>
            </button>
            <button value={1} onClick={changeStatus}>
              med
            </button>
            <button value={4} onClick={changeStatus}>
              avto
            </button>
            <button value={3} onClick={changeStatus}>
              famous
            </button>
          </div>
          <div>
            {successMessage && (
              <h2 className="edit-h2">FILE SUCCESSFULLY CREATED</h2>
            )}
          </div>
        </div>
        <div>
          <input type="text" onChange={fileteredData} />
          <ul className="item-list">
            {(filtered || data) &&
              (filtered || data).map((item) => (
                <li key={item.id}>
                  <button
                    onClick={() => {
                      setUserID(item.id);
                      setImage();
                    }}
                  >
                    {item.fullname}
                  </button>

                  {item.img && (
                    <a
                      href={url + item.img}
                      className="btn-id"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      user image
                    </a>
                  )}

                  {userID === item.id && (
                    <input
                      type="file"
                      onChange={(e) => setImage(e.target.files[0])}
                      accept=".png, .jpeg, .jpg"
                    />
                  )}
                  {userID === item.id && (
                    <button onClick={sendImage}>send</button>
                  )}

                  {image && userID === item.id && (
                    <img
                      className="selected-img"
                      width={450}
                      height={250}
                      src={URL.createObjectURL(image)}
                      alt="logo"
                    />
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default EditUserImage;

import Main from "./components/Main/Main";
import EditServiceNames from "./components/EditServiceNames/EditServiceNames";
import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import EditUserImage from "./components/EditUserImage/EditUserImage";

function App() {
  const [p, setP] = useState();
  let url = localStorage.getItem("url");

  if (!url) localStorage.setItem("url", process.env.REACT_APP_BASE_URL);

  return (
    <>
      {p !== process.env.REACT_APP_PASSWORD && (
        <input type="text" onChange={(e) => setP(e.target.value)} />
      )}

      {p === process.env.REACT_APP_PASSWORD ? (
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/edit-service-names" element={<EditServiceNames />} />
          <Route path="/edit-user-image" element={<EditUserImage />} />
        </Routes>
      ) : (
        <>not correct</>
      )}
    </>
  );
}

export default App;

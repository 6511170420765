import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./EditServiceNames.css";

function EditServiceNames() {
  const [services, setServices] = useState();
  const [serviceID, setServiceID] = useState();
  const [subServices, setSubServices] = useState();

  const [editedNameUz, setEditedNamesUz] = useState();
  const [editedNameRu, setEditedNamesRu] = useState();
  const [editedNameEn, setEditedNamesEn] = useState();

  const [editPressed, setEditPressed] = useState();

  const url = localStorage.getItem("url");

  useEffect(() => {
    return () => {
      (async () => {
        const res = await axios.get(url + "/services");
        setServices(res.data);
      })();
    };
  }, [url]);

  useEffect(() => {
    if (serviceID) {
      (async () => {
        const res = await axios.post(url + "/sub-services", { serviceID });
        setSubServices(res.data);
        setEditPressed();
      })();
    }
  }, [serviceID, url]);

  function sendChangesService(subServiceID) {
    if (!editedNameUz && !editedNameRu && !editedNameEn) return;
    axios.post(url + "/service-name-update", {
      id: subServiceID,
      uz: editedNameUz,
      ru: editedNameRu,
      en: editedNameEn,
    });
    setEditedNamesUz();
    setEditedNamesRu();
    setEditedNamesEn();
    setEditPressed();
  }

  function sendChangesSubService(subServiceID) {
    if (!editedNameUz && !editedNameRu && !editedNameEn) return;
    axios.post(url + "/sub-service-name-update", {
      id: subServiceID,
      uz: editedNameUz,
      ru: editedNameRu,
      en: editedNameEn,
    });
    setEditedNamesUz();
    setEditedNamesRu();
    setEditedNamesEn();
    setEditPressed();
  }

  return (
    <>
      <div>
        <button className="top-btn">
          <Link className="service-edit-btn" to={"/"}>
            back home
          </Link>
        </button>
        {services &&
          services.map((service) => (
            <div className="services-edit" key={service.id}>
              <button
                value={service.id}
                onClick={(e) => setServiceID(e.target.value)}
              >
                {service.name_ru}
              </button>
              {
                <input
                  type="text"
                  defaultValue={service.name_uz}
                  onChange={(e) => setEditedNamesUz(e.target.value)}
                  disabled={editPressed !== service.id}
                />
              }
              {
                <input
                  type="text"
                  defaultValue={service.name_ru}
                  onChange={(e) => setEditedNamesRu(e.target.value)}
                  disabled={editPressed !== service.id}
                />
              }
              {
                <input
                  type="text"
                  defaultValue={service.name_en}
                  onChange={(e) => setEditedNamesEn(e.target.value)}
                  disabled={editPressed !== service.id}
                />
              }
              {!editPressed && (
                <button onClick={() => setEditPressed(service.id)}>edit</button>
              )}
              {editPressed === service.id && (
                <button
                  onClick={() => {
                    sendChangesService(service.id);
                  }}
                >
                  send
                </button>
              )}
            </div>
          ))}
      </div>

      <div>
        {subServices &&
          subServices.map((subService) => (
            <div key={subService.id}>
              &nbsp; UZ:{" "}
              <input
                type="text"
                defaultValue={subService.name_uz}
                onChange={(e) => setEditedNamesUz(e.target.value)}
                disabled={editPressed !== subService.id}
              />
              &nbsp;&nbsp;RU:{" "}
              <input
                type="text"
                defaultValue={subService.name_ru}
                onChange={(e) => setEditedNamesRu(e.target.value)}
                disabled={editPressed !== subService.id}
              />
              &nbsp;&nbsp;EN:{" "}
              <input
                type="text"
                defaultValue={subService.name_en}
                onChange={(e) => setEditedNamesEn(e.target.value)}
                disabled={editPressed !== subService.id}
              />
              {editPressed !== subService.id && (
                <button onClick={() => setEditPressed(subService.id)}>
                  edit
                </button>
              )}
              {editPressed === subService.id && (
                <button
                  onClick={() => {
                    setEditPressed(subService.id);
                    sendChangesSubService(subService.id);
                    setEditPressed();
                  }}
                >
                  send
                </button>
              )}
            </div>
          ))}
      </div>
    </>
  );
}

export default EditServiceNames;

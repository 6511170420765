const latinGivesKiril = {
  a: "а",
  b: "б",
  d: "д",
  e: "е",
  f: "ф",
  g: "г",
  h: "х",
  i: "и",
  j: "ж",
  k: "к",
  l: "л",
  m: "м",
  n: "н",
  o: "о",
  p: "п",
  q: "к",
  r: "р",
  s: "с",
  t: "т",
  u: "у",
  v: "в",
  x: "х",
  y: "й",
  z: "з",
  ch: "ч",
  sh: "ш",
  ya: "я",
  yu: "ю",
  yo: "е",
};
const kirilGivesLatin = {
  а: "a",
  б: "b",
  д: "d",
  э: "e",
  е: "e",
  ф: "f",
  г: "g",
  х: "h",
  и: "i",
  ж: "j",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  в: "v",
  й: "y",
  з: "z",
  ч: "ch",
  ш: "sh",
  я: "ya",
  ю: "yu",
};

function changeEncoding(text) {
  if (!text) return "";

  text = text.toLowerCase();
  const splitted = text.split("");

  let skipNext = false;
  const result = splitted.map((item, idx) => {
    let joinedLettets = item + splitted[idx + 1];
    if (skipNext) {
      skipNext = false;
      return "";
    }
    if (latinGivesKiril[joinedLettets]) skipNext = true;
    if (item === " ") return " ";
    return (
      latinGivesKiril[joinedLettets] ||
      kirilGivesLatin[item] ||
      latinGivesKiril[item] ||
      ""
    );
  });

  return result.join("");
}

export { changeEncoding };

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Main.css";

// pm2 start --name dashboard npm -- start

function Main() {
  const [services, setServices] = useState();
  const [serviceID, setServiceID] = useState();
  const [subServices, setSubServices] = useState();
  const [subServiceID, setSubServiceID] = useState();
  const [color, setColor] = useState();
  const [timeOut, setTimeOut] = useState();

  const [userServiceNames, setUserServiceNames] = useState();
  const [selectedId, setSelectId] = useState();
  const [selectedType, setSelectType] = useState();

  const [ads, setAds] = useState();

  const [img, setImg] = useState();
  const [imgToShow, setImgToShow] = useState();
  const [adsImg, setAdsImg] = useState();

  let url_1 = process.env.REACT_APP_BASE_URL;
  let url_2 = process.env.REACT_APP_DEV_URL;

  let url = localStorage.getItem("url");

  let existsServiceTypes = {
    "6d22b23e-3de1-40c5-81af-6515b155cac3": ["FAMOUS"],
    "2a3a220c-106c-4355-83c5-1f3b54a17a82": ["DOCTOR", "CLINIC"],
    "8fd60817-d8a3-44a2-b27b-dba8d2cad048": [
      "RESTAURANT",
      "RESTAURANT_WEDDING",
    ],
  };

  useEffect(() => {
    return () => {
      (async () => {
        const res = await axios.get(url + "/services");
        setServices(res.data);
      })();
    };
  }, [url]);

  useEffect(() => {
    if (serviceID) {
      (async () => {
        const res = await axios.post(url + "/sub-services", { serviceID });
        const resAds = await axios.post(url + "/get-service-ads", {
          serviceID,
        });
        setAds(resAds.data);
        setSubServices(res.data);
      })();
    }
  }, [serviceID, url]);

  useEffect(() => {
    if (imgToShow === false) {
      setTimeout(async () => {
        const resAds = await axios.post(url + "/get-service-ads", {
          serviceID,
        });
        setAds(resAds.data);
      }, 800);
      setImgToShow();
    }
  }, [imgToShow, serviceID, url]);

  useEffect(() => {
    if (timeOut === "sub") {
      setTimeout(async () => {
        const ress = await axios.post(url + "/sub-services", { serviceID });
        setSubServices(ress.data);
      }, 800);
      setTimeOut();
    }
  }, [serviceID, url, timeOut]);

  useEffect(() => {
    if (timeOut === "ser") {
      setTimeout(async () => {
        const ress = await axios.get(url + "/services");
        setServices(ress.data);
      }, 800);
      setTimeOut();
    }
  }, [serviceID, url, timeOut]);

  useEffect(() => {
    if (serviceID && imgToShow?.img) {
      (async () => {
        const res = await axios.get(
          url + `/get-user-service-names/${serviceID}`
        );
        setUserServiceNames(res.data.data);
      })();
    }
  }, [url, serviceID, imgToShow]);

  async function sendFile(subServiceID, serviceid) {
    setSubServiceID();
    setTimeOut("sub");
    let image = new FormData();
    image.append("file", img);
    // if (!img) return
    if (subServiceID) {
      axios.post(url + "/sub-service-image", image, {
        headers: {
          "Content-Type": "multipart/form-data",
          subService: subServiceID,
        },
      });
    } else {
      setTimeOut("ser");
      const verifyColorHex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
      if (color && !verifyColorHex.test(color)) return alert("Color isn't hex");

      axios.post(url + "/service-image", image, {
        headers: {
          "Content-Type": "multipart/form-data",
          serviceid,
          color,
        },
      });
      setColor();
    }
    setImg();
  }

  function takeServiceID(e) {
    setServiceID(e.target.value);
  }

  function btnX() {
    setImg();
    setSubServiceID();
    document.querySelectorAll(".service-img").value = "";
  }

  function sendAdsBanner() {
    if (!imgToShow?.img || !serviceID) return;
    let file = new FormData();
    file.append("file", imgToShow.img[0]);
    file.append("serviceID", serviceID);
    file.append("userServiceId", selectedId);
    file.append("userServiceType", selectedType);
    axios.post(url + "/create-service-ads", file);
    document.querySelector(".ads-input-add").value = "";
    setImgToShow(false);
    setSelectType();
    setSelectId();
  }

  function deleteBannerAds(e) {
    if (e.target.value) {
      axios.post(url + "/delete-service-ads", { adsID: e.target.value });
    }
    setImgToShow(false);
  }

  function updateBannerAds() {
    if (!adsImg?.img || !adsImg.imgID) return;
    let file = new FormData();
    file.append("file", adsImg.img[0]);
    file.append("adsID", adsImg.imgID);
    axios.post(url + "/update-service-ads", file);
    document.querySelector(".ads-input-update").value = "";

    setAdsImg();
    setImgToShow(false);
  }

  function imageSelect(incomingImg, localImage, imgID) {
    if (adsImg) {
      if (adsImg.imgID === imgID) {
        return localImage;
      } else return url + incomingImg;
    } else return url + incomingImg;
  }

  function changeUrl(url) {
    localStorage.setItem("url", url);
    window.location.reload();
  }

  return (
    <>
      <button>
        <Link className="service-edit-btn" to={"/edit-service-names"}>
          Edit service names
        </Link>
      </button>
      <button>
        <Link className="service-edit-btn" to={"/edit-user-image"}>
          Edit user image
        </Link>
      </button>
      <button onClick={() => changeUrl(url_1)} disabled={url === url_1}>
        {url_1}
      </button>
      <button onClick={() => changeUrl(url_2)} disabled={url === url_2}>
        {url_2}
      </button>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.target.reset();
        }}
      >
        {services &&
          services.map((service) => (
            <div className="services" key={service.id}>
              {service.service_img && (
                <img
                  src={`${url}${service.service_img}`}
                  width="50"
                  height="50"
                  alt="pic"
                />
              )}
              <button value={service.id} onClick={takeServiceID}>
                {service.name_ru}
              </button>
              <div>
                #
                <input
                  type="text"
                  placeholder={service.color_img}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>
              <div>
                <input
                  className="service-img"
                  type="file"
                  disabled={subServiceID && subServiceID !== service.id}
                  accept=".jpg, .png, .jpeg, .svg"
                  onChange={(e) => {
                    setImg(e.target.files[0]);
                    setSubServiceID(service.id);
                  }}
                />
                <button className="btnX" onClick={btnX}>
                  x
                </button>
              </div>
              <button
                onClick={() => sendFile(null, service.id)}
                disabled={subServiceID && subServiceID !== service.id}
              >
                send
              </button>
            </div>
          ))}
      </form>

      <div className="service-wrapper">
        {/* -------------------- Service names ------------------- */}
        <ul className="list">
          <h2>Service names</h2>
          {subServices &&
            subServices.map((subService) => (
              <div className="sub-service" key={subService.id}>
                <li>{subService.name_ru}</li>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.target.reset();
                  }}
                >
                  <input
                    disabled={subServiceID && subServiceID !== subService.id}
                    className="file-input"
                    type="file"
                    onChange={(e) => {
                      setImg(e.target.files[0]);
                      setSubServiceID(subService.id);
                    }}
                  />
                  <button
                    className="btn"
                    onClick={() => sendFile(subService.id)}
                    disabled={subServiceID && subServiceID !== subService.id}
                  >
                    send
                  </button>
                  {subService.sub_service_img && (
                    <img
                      src={`${url}${subService.sub_service_img}`}
                      width="30"
                      height="30"
                      alt="pic"
                    />
                  )}
                </form>
              </div>
            ))}
        </ul>

        {/* ------------------ Service ads -------------------- */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ul className="list">
            <h2>Service ads</h2>
            {ads &&
              ads.map((ads) => (
                <li key={ads.id}>
                  {ads.image_url && (
                    <img
                      src={`${imageSelect(
                        ads?.image_url,
                        adsImg?.imgUrl,
                        ads.id
                      )}`}
                      alt="pic"
                      width={240}
                      height={135}
                    />
                  )}

                  <input
                    type="file"
                    className="ads-input-update"
                    onChange={(e) => {
                      e.target?.files[0] &&
                        setAdsImg({
                          img: e.target.files,
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgID: ads.id,
                        });
                    }}
                  />
                  {adsImg?.imgID !== ads.id && (
                    <button value={ads.id} onClick={deleteBannerAds}>
                      delete
                    </button>
                  )}
                  <button
                    onClick={() => setAdsImg()}
                    disabled={adsImg?.imgID !== ads.id}
                  >
                    cancel
                  </button>
                  <button
                    onClick={updateBannerAds}
                    disabled={adsImg?.imgID !== ads.id}
                  >
                    send
                  </button>
                </li>
              ))}
          </ul>
          {imgToShow && (
            <img src={imgToShow?.imgUrl} alt="pic" width={240} height={135} />
          )}
          <div className="add-ads-block">
            <input
              type="file"
              className="ads-input-add"
              onChange={(e) => {
                e.target?.files[0] &&
                  setImgToShow({
                    img: e.target?.files,
                    imgUrl: URL.createObjectURL(e.target?.files[0]),
                  });
              }}
            />
            <select onChange={(e) => setSelectType(e.target.value)}>
              <option defaultValue="" disabled selected>
                xizmat turi
              </option>
              {serviceID &&
                existsServiceTypes[serviceID].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            <select onChange={(e) => setSelectId(e.target.value)}>
              <option defaultValue="" disabled selected>
                xizmat nomi
              </option>
              {userServiceNames &&
                userServiceNames.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <button onClick={() => setImgToShow()}>cancel</button>
          <button onClick={sendAdsBanner}>send</button>
        </form>
      </div>
    </>
  );
}

export default Main;
